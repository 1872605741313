import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import CopyButton from '../../src/CopyButton';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "copybutton"
    }}>{`CopyButton`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import CopyButton from '@mfrm/mfcl/CopyButton'
`}</code></pre>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={CopyButton} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <Playground __position={1} __code={'<CopyButton displayText=\"You\'ve copied me!\" />\n<input type=\"text\" id=\"paste-box\" placeholder=\"Paste here\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      CopyButton,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <CopyButton displayText="You've copied me!" mdxType="CopyButton" />
  <input type='text' id='paste-box' placeholder='Paste here' />
    </Playground>
    <h2 {...{
      "id": "copy-text-other-than-displayed-text"
    }}>{`Copy text other than displayed text`}</h2>
    <Playground __position={2} __code={'<CopyButton\n  displayText=\"This is my phone number (555) 123-4567\"\n  textToCopy=\"5551234567\"\n/>\n<input type=\"text\" id=\"paste-box-formatted\" placeholder=\"Paste here\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      CopyButton,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <CopyButton displayText="This is my phone number (555) 123-4567" textToCopy='5551234567' mdxType="CopyButton" />
  <input type='text' id='paste-box-formatted' placeholder='Paste here' />
    </Playground>
    <h2 {...{
      "id": "icon-on-right"
    }}>{`Icon on right`}</h2>
    <Playground __position={3} __code={'<CopyButton displayText=\"The icon is over there!\" iconOnRight />\n<input type=\"text\" id=\"paste-box-icon-on-right\" placeholder=\"Paste here\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      CopyButton,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <CopyButton displayText="The icon is over there!" iconOnRight mdxType="CopyButton" />
  <input type='text' id='paste-box-icon-on-right' placeholder='Paste here' />
    </Playground>
    <h2 {...{
      "id": "other-colors"
    }}>{`Other colors`}</h2>
    <Playground __position={4} __code={'<CopyButton displayText=\"The icon is #FF0000\" iconFillColor=\"#FF0000\" />\n<input type=\"text\" id=\"paste-box-icon-fill-red\" placeholder=\"Paste here\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      CopyButton,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <CopyButton displayText="The icon is #FF0000" iconFillColor='#FF0000' mdxType="CopyButton" />
  <input type='text' id='paste-box-icon-fill-red' placeholder='Paste here' />
    </Playground>
    <Playground __position={5} __code={'<CopyButton\n  displayText=\"The icon background is #00FF00\"\n  iconBackgroundFillColor=\"#00FF00\"\n/>\n<input\n  type=\"text\"\n  id=\"paste-box-icon-background-green\"\n  placeholder=\"Paste here\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      CopyButton,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <CopyButton displayText="The icon background is #00FF00" iconBackgroundFillColor='#00FF00' mdxType="CopyButton" />
  <input type='text' id='paste-box-icon-background-green' placeholder='Paste here' />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      